import { Component, Input, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-svg',
  templateUrl: './svg.component.svg',
})
export class SvgComponent implements OnInit {
  @Input({ required: true }) icon!: string;
  @Input({ required: true }) blockSize!: number;
  @Input({ required: true }) inlineSize!: number;
  @Input() fill: string = 'var(--icon-fill)';
  blockSizeEm = '';
  inlineSizeEm = '';

  ngOnInit() {
    this.blockSizeEm = this.convertToEm(this.blockSize);
    this.inlineSizeEm = this.convertToEm(this.inlineSize);
  }

  convertToEm(valuePx: number) {
    if (isNaN(valuePx)) {
      return `1em`;
    }
    return `${valuePx / 16}em`;
  }
}
